import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Screen1 } from '../../../components/images/support/getting-started/Screen1.generated';
import { CopyWorkflow1 } from '../../../components/images/support/workflows/CopyWorkflow1.generated';
import { CopyWorkflow2 } from '../../../components/images/support/workflows/CopyWorkflow2.generated';
import { CopyWorkflow3 } from '../../../components/images/support/workflows/CopyWorkflow3.generated';
import { CopyWorkflow4 } from '../../../components/images/support/workflows/CopyWorkflow4.generated';
import { CopyWorkflow5 } from '../../../components/images/support/workflows/CopyWorkflow5.generated';
import { CopyWorkflow6 } from '../../../components/images/support/workflows/CopyWorkflow6.generated';
import { CopyWorkflow7 } from '../../../components/images/support/workflows/CopyWorkflow7.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "copy-a-workflow-️-import--export"
    }}>{`Copy a Workflow ⚡️ (Import / Export)`}</h1>
    <p>{`Workflows can be copied between two different businesses.`}</p>
    <p>{`Let's get started`}</p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to the `}<strong parentName="li">{`Workflows`}</strong>{` menu.`}</li>
    </ol>

    <Screen1 mdxType="Screen1" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click the menu of the workflow you want to copy.`}</li>
    </ol>

    <CopyWorkflow1 mdxType="CopyWorkflow1" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Click `}<strong parentName="li">{`Copy export code`}</strong>{`.`}</li>
    </ol>

    <CopyWorkflow2 mdxType="CopyWorkflow2" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Change to the business you wish to copy the workflow to (or you can stay in the same business).`}</li>
    </ol>

    <CopyWorkflow3 mdxType="CopyWorkflow3" />
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Click `}<strong parentName="li">{`Import`}</strong>{` button.`}</li>
    </ol>

    <CopyWorkflow4 mdxType="CopyWorkflow4" />
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Paste the code into the field`}</li>
    </ol>

    <CopyWorkflow5 mdxType="CopyWorkflow5" />
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`You can now see a preview of the workflow. Update the workflow name if needed.`}</li>
    </ol>

    <CopyWorkflow6 mdxType="CopyWorkflow6" />
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Now you can see and edit the copy of the workflow.`}</li>
    </ol>

    <CopyWorkflow7 mdxType="CopyWorkflow7" />
    <h3 {...{
      "id": "great-work-youre-all-done-"
    }}>{`Great work you're all done 🥳`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      